import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Page_footer extends PureComponent {
   render() {
      return (
         <footer className="footer">
            <p className="copyright">Copyright © {new Date().getFullYear()} HOME TO ALL</p>
         </footer>
      )
   }
}

Page_footer.propTypes = {}

Page_footer.defaultProps = {}

export default Page_footer
