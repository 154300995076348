import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"

import Link from "../components/LinkWithTransitionLock";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (
      // <>

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">台北萬華　最美的是人</h2>
          <h1 className="main_title">策展人沈伯丞＆作家林立青</h1>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_muse_1_a">
            <div className="column_6">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/6qRHSSbTUdM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </section>

        </div>


        <div className="page_footer">
          <Link to="/project_a_muse_2" className="next_page">
            <div className="next_title">八坪大</div>
            <div className="next_owner">店主/歐陽如修　筆者/林立青</div>
          </Link>
        </div>

      </div>

      // </>
    )

  }

}


export default Page

