import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_a_muse_1.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
          title="策展人沈伯丞＆作家林立青 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "策展人沈伯丞＆作家林立青",
            },
            {
              property: `og:title`,
              content: "策展人沈伯丞＆作家林立青 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "策展人沈伯丞＆作家林立青",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/project_a_muse_1-2.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;